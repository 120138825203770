<template>
  <div class="auth">
    <div class="auth__container _container">
      <h1 class="auth__title">{{ $t("login.title") }}</h1>
      <div class="auth__tabs">
        <TabItem
          :tabItems="tabs"
          :selected="selectedTab"
          :type="'border'"
          @setTab="selectTab"
        />
      </div>

      <TabBlock :is-selected="selectedTab === this.tabs[0].id">
        <div class="login">
          <VeeForm @submit="signIn" class="login__form">
            <div class="login__block">
              <div class="login__item">
                <VeeField
                  name="email"
                  type="text"
                  class="login__input main-input"
                  :placeholder="$t('placeholder.login')"
                  v-model="loginForm.email"
                  rules="required|email"
                />
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="email"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="login__item">
                <div>
                  <VeeField
                    name="password"
                    :type="showPass ? 'password' : 'text'"
                    class="login__input main-input"
                    :placeholder="$t('placeholder.password')"
                    v-model="loginForm.password"
                    rules="required"
                  />
                  <span class="eye" @click="showPass = !showPass">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="password"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <transition name="error">
                <span class="error-message">{{ errorMessage }}</span>
              </transition>
            </div>
            <div class="login__block">
              <router-link to="/reset-password" class="login__link">{{
                $t("login.forgot")
              }}</router-link>
            </div>
            <div class="login__block">
              <button class="login__btn main-btn" type="submit">
                {{ $t("auth") }}
              </button>
              <div class="login__text">
                {{ $t("login.dont") }}
              </div>
              <div
                class="login__text"
                @click="selectTab(tabs[1].id)"
                v-html="$t('login.createLink', { create: $t('login.create') })"
              ></div>
            </div>
          </VeeForm>
        </div>
      </TabBlock>
      <TabBlock :is-selected="selectedTab === this.tabs[1].id">
        <div class="registration">
          <VeeForm @submit="registration" class="registration__form">
            <div class="registration__block">
              <label for="individual" class="registration__radio">
                <input
                  type="radio"
                  name="user"
                  id="individual"
                  value="individual"
                  v-model="registrationForm.type"
                />
                <span
                  class="registration__icon"
                  :class="{ active: registrationForm.type === 'individual' }"
                ></span>

                {{ $t("fiz") }}
              </label>
              <label for="entity" class="registration__radio">
                <span
                  class="registration__icon"
                  :class="{ active: registrationForm.type === 'entity' }"
                ></span>
                <input
                  type="radio"
                  name="user"
                  id="entity"
                  value="entity"
                  v-model="registrationForm.type"
                />
                {{ $t("yur") }}
              </label>
            </div>
            <div class="registration__block">
              <div class="registration__item">
                <VeeField
                  name="name"
                  rules="required"
                  type="text"
                  class="registration__input main-input"
                  :placeholder="
                    registrationForm.type === 'individual'
                      ? $t('placeholder.name')
                      : $t('placeholder.organization')
                  "
                  v-model="registrationForm.name"
                />
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="name"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div
                class="registration__item"
                v-if="registrationForm.type === 'entity'"
              >
                <VeeField
                  name="bin"
                  label="БИН"
                  rules="numeric"
                  type="text"
                  class="registration__input main-input"
                  :placeholder="$t('placeholder.bin')"
                  v-model="registrationForm.bin"
                />
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="bin"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div
                class="registration__item"
                v-if="registrationForm.type === 'entity'"
              >
                <input
                  type="text"
                  class="registration__input main-input"
                  :placeholder="$t('placeholder.yur')"
                  v-model="registrationForm.entity_address"
                />
              </div>
              <div
                class="registration__item"
                v-if="registrationForm.type === 'entity'"
              >
                <input
                  type="text"
                  class="registration__input main-input"
                  :placeholder="$t('placeholder.fact')"
                  v-model="registrationForm.actual_address"
                />
              </div>
              <div
                class="registration__item"
                v-if="registrationForm.type === 'individual'"
              >
                <input
                  type="text"
                  class="registration__input main-input"
                  :placeholder="$t('placeholder.surname')"
                  v-model="registrationForm.surname"
                />
              </div>
              <div class="registration__item">
                <div class="counry">
                  <div class="counry-selected" @click="tooglelang = !tooglelang">
                    <img :src="require('../assets/images/langs/'+selectedLangs.img)" alt="flau" />
                    {{ selectedLangs.initial }}
                    <p style="padding-left: 10px;">{{ selectedLangs.phone }}</p>                    
                  </div>
                  <div class="counry-list" v-if="tooglelang">
                    <template v-for="item in langs" :key="item.id">
                      <div class="counry-list-item" @click="selectedLangs = item, tooglelang = false, registrationForm.phone = ''" v-if="item.id !== selectedLangs.id" >
                        <img :src="require('../assets/images/langs/'+item.img)" alt="flau" />
                        {{ item.initial }}
                      </div>
                    </template>
                  </div>
                </div>
                <VeeField
                  type="tel"
                  name="phone"
                  rules="required"
                  :maxlength="selectedLangs.id !== 1 ? 14 : 13"
                  class="registration__input main-input phonemask"
                  v-mask="selectedLangs.id !== 1 ? '(000) 000 0000' : '(000) 000-000'"
                  :placeholder="selectedLangs.id !== 1 ? '(XXX) XXX XXXX' : '(XXX) XXX-XXX'"
                  v-model="registrationForm.phone"
                />
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="phone"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="registration__item">
                <VeeField
                  type="email"
                  name="email"
                  rules="required|email"
                  class="registration__input main-input"
                  placeholder="E-mail*"
                  v-model="registrationForm.email"
                  auto
                />
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="email"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="registration__item">
                <div>
                  <VeeField
                    :type="showPass1 ? 'password' : 'text'"
                    name="password"
                    rules="required|min:6"
                    class="registration__input main-input"
                    :placeholder="$t('placeholder.password')"
                    v-model="registrationForm.password"
                  />
                  <span class="eye" @click="showPass1 = !showPass1">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass1 ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="password"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="registration__item">
                <div>
                  <VeeField
                    :type="showPass2 ? 'password' : 'text'"
                    name="password_confirm"
                    rules="required|min:6"
                    class="registration__input main-input"
                    :placeholder="$t('placeholder.confirmPassword')"
                    v-model="registrationForm.password_confirmation"
                  />
                  <span class="eye" @click="showPass2 = !showPass2">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          !showPass2 ? 'eyeshow' : 'eyehide'
                        }.png`)
                      "
                    />
                  </span>
                </div>
                <transition name="error">
                  <VeeErrorMessage
                    class="error-message"
                    name="password_confirm"
                  ></VeeErrorMessage>
                </transition>
              </div>
            </div>
            <div class="registration__block">
              <button class="registration__btn main-btn" type="submit">
                {{ $t("login.register") }}
              </button>
            </div>
            <div class="registration__block">
              <div class="registration__accept">
                <VeeField
                  name="privacy"
                  rules="required"
                  value="privacy"
                  type="checkbox"
                  id="accept"
                  class="registration__checkbox main-checkbox"
                />
                <label
                  for="accept"
                  class="registration__small-text"
                  v-html="$t('uslovie')"
                >
                </label>
              </div>
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="privacy"
                ></VeeErrorMessage>
              </transition>
            </div>
          </VeeForm>
        </div>
      </TabBlock>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import { mapActions } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  components: {
    TabBlock,
    TabItem,
    VeeForm: Form,
    VeeField: Field,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showPass: true,
      tooglelang: false,
      langs: [
        {
          id: 0,
          initial: "KZ",
          img: "kz.png",
          phone: "+7 "
        },
        {
          id: 1,
          initial: "KG",
          img: "kyr.png",
          phone: "+996 "
        },
        {
          id: 2,
          initial: "RU",
          img: "ru.png",
          phone: "+7 "
        },
      ],
      selectedLangs: {
          id: 0,
          initial: "KZ",
          img: "kz.png",
          phone: "+7 "
        },
      showPass1: true,
      showPass2: true,
      selectedTab: 0,
      tabs: [
        { id: 1, name: "auth" },
        { id: 2, name: "login.create" },
      ],
      loginForm: {
        email: "",
        password: "",
      },
      registrationForm: {
        type: "individual",
        name: "",
        surname: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
        bin: "",
        entity_address: "",
        actual_address: "",
      },
      errorMessage: "",
    };
  },
  created() {
    this.selectedTab = this.tabs[0].id;
  },
  methods: {
    ...mapActions(["checkAuth", "fetchOrders"]),
    selectTab(tab) {
      this.selectedTab = tab;
    },
    registration() {
      this.registrationForm.phone = this.selectedLangs.phone + this.registrationForm.phone
      axios
        .post("V1/register", this.registrationForm)
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          this.checkAuth();  
          this.fetchOrders();
          this.$router.push("/personal-account");        
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors.email?.length)
            this.toast.error(err.response.data.errors.email[0]);
          else if (err.response.data.errors.password?.length)
            this.toast.error(err.response.data.errors.password[0]);
          else if (err.response.data.errors.phone?.length)
          this.toast.error(err.response.data.errors.phone[0]);
        });
    },
    signIn() {
      axios
        .post("V1/login", this.loginForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("type", response.data.data.type);
          this.checkAuth();
          this.fetchOrders();
          this.$router.push("/personal-account");       
        })
        .catch((errors) => {
          let errorMessages = errors.response.data;
          if (errorMessages.message === "Неверный пароль") {
            this.errorMessage = this.$t("errors.noPassword");
          } else {
            this.errorMessage = this.$t("errors.noLogin");
          }
        });
    },
  },
};
</script>

<style lang="scss">
.auth {
  padding: 100px 97px;
  @media (max-width: $mobile + px) {
    padding: 65px 0;
  }

  &__container {
    max-width: 460px !important;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 37px 0;
    text-align: center;
    @media (max-width: $mobile + px) {
      font-size: 20px;
    }
  }

  &__tabs {
    margin: 0 0 30px 0;
  }

  .tab-list {
    margin: 0 auto;
  }

  .tab-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #555555;
  }
}

.login,
.registration {
  &__block {
    text-align: center;

    &:not(:last-child) {
      margin: 0 0 30px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 20px 0;
      }
    }
  }

  &__item {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #77757F;
    padding: 0px;
    input {
      border: none;
    }
    &:not(:last-child) {
      margin: 0 0 17px 0;
    }
    div {
      width: 100%;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #77757f;
      .main-input {
        border: none;
        outline: none;
      }
      .eye {
        padding: 0 12px;
        img {
          width: 18px;
        }
      }
    }
    .counry {
      position: relative;
      width: auto;
      cursor: pointer;
      &-selected {        
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 10px;
      }
      &-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        gap: 5px;
        background: white;
        left: 10px;
        top: 100%;
        &-item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
      img {
        width: 15px;
        height: 10px;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    span {
      cursor: pointer;
      border-bottom: 1px solid #d81921;
      color: #d81921;
    }

    @media (max-width: $mobileSmall + 14px) {
      font-size: 12px;
    }
  }
}

.login {
  &__link {
    text-decoration: underline !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #77757f;
  }

  &__btn {
    margin: 0 0 22px 0;
  }
}

.phonemask {
  padding-left: 5px !important;
}
.registration {
  &__block {
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px 35px;
      @media (max-width: $mobileSmall + px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  &__accept {
    display: flex;
    align-items: flex-start;
    gap: 0 9px;
  }

  &__checkbox {
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;

    &:after {
      background: url("../assets/icons/shopping-cart/check.svg") center
        no-repeat;
    }
  }

  &__small-text {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  &__radio {
    display: flex;
    align-items: center;
    text-align: left;
    white-space: nowrap;

    input {
      display: none;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1.5px solid #bcbcbb;
    background: #ffffff;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 1.5px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: #d81921;
      border-radius: 50%;
      transform: scale(0);
    }

    &.active {
      &:after {
        transform: scale(1);
      }
    }
  }
}
</style>